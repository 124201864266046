<template>
<div>
<router-view />
</div>
</template>

<script>

import moduleSettingManagement from '@/store/settings/moduleSettingManagement.js'

export default {

  data () {
    return {
        routes: this.$router.options.routes,
		companySlug: typeof this.$route.params.slug !== 'undefined' ? this.$route.params.slug : '',
		apikey: '',
		checkBy: 'slug'
    }
  },
  components: {
  },
  computed: {
    // customerSettings () {
    //   return this.$store.state.settingManagement.customerSetting
    // },
  },
  methods: {
    async fetchCustomerSettings() {
      this.$vs.loading()
      await this.$store.dispatch('settingManagement/fetchReservationCustomerSettings')
      .then(res => {
          this.borneIsActive(res.data.config)
          if(typeof this.$route.name == 'undefined') {
          if(this.checkBy == 'slug') {
            this.$router.push(`/${this.$route.params.slug}/welcomepage`)
          }
          if(this.checkBy == 'domain') {
            this.$router.push(`/welcomepage`)
          }
        }
        this.$vs.loading.close()
      })
      .catch(err => { 
        console.error(err)
        this.$vs.loading.close()
      })
    },
    async checkTenancy(companySlug) {

		console.log('companySlug', companySlug)

		this.apikey = companySlug ? companySlug : window.location.hostname
		this.checkBy = companySlug ? 'slug' : 'domain' 

		console.log('companySlug', companySlug)
		console.log('apikey', this.apikey)
		
		if(this.$route.params.slug==='error404') {
			//
		}
		
		if(companySlug === '' && this.apikey == '') return
		
		const payload = {
			apikey : this.apikey,
			by : this.checkBy,
        }

		this.$vs.loading()

		await this.$store.dispatch('settingManagement/checkCompany', payload)
		.then(res => {
			this.$store.commit("SET_COMPANY_INFO", {
				domain: res.data.domain,
				slug: res.data.slug,
				uuid: res.data.uuid,
				checkBy: this.checkBy,
			});
            this.fetchCustomerSettings()
		})
		.catch(err => {
			console.error(err)
			this.$vs.loading.close()
			this.$router.push('/error404')
		})
    },
    borneIsActive(config) {
		if(typeof config === 'undefined' || typeof config.borne_is_active === 'undefined' || typeof this.$route.query.borne === 'undefined') return
		if(config.borne_is_active == true && this.$route.query.borne == "true") {
			localStorage.setItem('modeBorneIsActive', true)
		}
    }
  },
  created() {
	localStorage.removeItem('modeBorneIsActive')
    if (!moduleSettingManagement.isRegistered) {
        this.$store.registerModule('settingManagement', moduleSettingManagement)
        moduleSettingManagement.isRegistered = true
    }
    this.companySlug = typeof this.$route.params.slug !== 'undefined' ? this.$route.params.slug : ''
    this.checkTenancy(this.companySlug);
  }
}

</script>

<style lang="scss" scoped>

.bg-primary, a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(var(--vs-primary), 1)!important;
}

</style>

